<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <company-editor
            :key="isCreating"
            class="pa-0"
            :company-id="companyId"
            :is-creating="isCreating"
            show-title-back-button
            @saved="onSaved"
            @cancelled="onCancelled"
        >
            <template #loading>
                <v-card-title>
                    <v-skeleton-loader loading type="heading" width="75%" />
                </v-card-title>
                <v-card-text>
                    <v-skeleton-loader loading type="paragraph" />
                </v-card-text>
            </template>
        </company-editor>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyEditor } from '@/components/CompanyEditor';

import type { ServerResponse } from '@/types/ServerResponse';
import type { Company } from '@/types/Company';

@Component({
    components: {
        CompanyEditor
    }
})
export default class CompanyEditorInline extends Vue {
    get companyId() {
        return Number(this.$route.params.companyId) || 0;
    }

    get isCreating() {
        return this.$route.params.companyId === 'create';
    }

    onCancelled() {
        if (!this.tryReturn()) {
            this.$router.push('/profile/companies');
        }
    }

    onSaved(response: ServerResponse<{ company: Company }>) {
        if (this.isCreating) {
            if (response?.data.company?.id) {
                this.$router.push(
                    `/profile/companies/${response.data.company.id}`
                );
            }
        } else {
            this.tryReturn();
        }
    }

    tryReturn() {
        if (this.$route.query.return_uri) {
            this.$router.push(String(this.$route.query.return_uri));

            return true;
        }

        return false;
    }
}
</script>
